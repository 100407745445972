import React from "react"
import { Container } from "@material-ui/core"
import Header from "../components/header"
export default () => (
  <div style={{ position: "absolute", top: 0, left: 0, right: 0, bottom: 0 }}>
    <div style={{ width: "100%", height: "100%", overflowY: 'scroll' }}>
      <Header />
      <Container maxWidth="md">
        <h1>Politica de Privacidade</h1>

        <h2>Acesso ao Site</h2>

        <p>
          Todas as suas informações pessoais recolhidas, serão usadas para o
          ajudar a tornar a sua visita no nosso site o mais produtiva e
          agradável possível.
        </p>
        <p>
          A garantia da confidencialidade dos dados pessoais dos utilizadores do
          site é importante para nós.
        </p>
        <p>
          Todas as informações pessoais relativas a membros, assinantes,
          clientes ou visitantes que usem o site AkiSuaFeira serão tratadas em
          concordância com a Lei da Proteção de Dados Pessoais de 26 de outubro
          de 1998 (Lei n.º 67/98).
        </p>
        <p>
          A informação pessoal recolhida é o seu cadastro contendo: Nome,
          Apelido, telefone, e-mail e endereço completo. Todas essas informações
          servirão única e exclusivamente para ficar catalogado em nossa base de
          dados para dar agilidade no nosso atendimento.{" "}
        </p>
        <p>
          O uso do site AkiSuaFeira e subdominios pressupõe a aceitação deste
          Acordo de privacidade. A equipe da AkiSuaFeira reserva-se ao direito
          de alterar este acordo sem aviso prévio. Deste modo, recomendamos que
          consulte a nossa política de privacidade com regularidade de forma a
          estar sempre atualizado.
        </p>

        <h2>Os Cookies e Web Beacons</h2>

        <p>
          Utilizamos cookies para armazenar informação, tais como as suas
          preferências pessoas quando visita o nosso website.
        </p>
        <p>
          Você detém o poder de desligar os seus cookies, nas opções do seu
          browser, ou efetuando alterações nas ferramentas de programas
          Anti-Virus, como o Norton Internet Security. No entanto, isso poderá
          alterar a forma como interage com o nosso website, ou outros websites.
          Isso poderá afetar ou não permitir que faça logins em programas, sites
          ou fóruns da nossa e de outras redes.
        </p>
      </Container>
    </div>
  </div>
)
