import React, { Component } from "react"
import { navigate } from "gatsby"
import style from "./index.module.css"
import brand from "../../img/logo-brand.png"

class Header extends Component {
  constructor(props) {
    super(props)
    this.state = {
      show: false,
      scrollPos: 0,
    }
    this.handleScroll = this.handleScroll.bind(this)
  }

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll)
  }

  handleScroll = () => {
    const { scrollPos } = this.state
    this.setState({
      scrollPos: -document.getElementById("wrapper").getBoundingClientRect()
        .top,
      show: scrollPos > 180,
    })
  }

  render() {
    //const showHeadder = this.state.show ? "active" : "hidden"
    return (
      <div>
        <header className={style.header}>
          <div className={style.headerContainerContent}>
              <img
                src={brand}
                alt="akisuafeira"
                className={style.brand}
                onClick={()=>navigate("/")}
              ></img>
          </div>
        </header>
      </div>
    )
  }
}

export default Header
